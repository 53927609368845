import { template as _commonTemplate } from '@shein/common-function'
import { labelTypeMap } from 'public/src/pages/components/productItemV3/utils/constant.js'
import { NonRefundableType } from 'public/src/pages/checkout/vue_tpls/shopping_bag/config.js'

export function prefetchScripts({ resources }) {
  // 对资源列表进行遍历，并创建对应的 link 元素进行预取
  resources.forEach(resource => {
    const link = document.createElement('link')
    link.setAttribute('rel', 'prefetch')
    link.setAttribute('href', resource)

    if (resource.includes('.png') || resource.includes('.jpg')) {
      link.setAttribute('as', 'image')
    } else if (resource.includes('.js')) {
      link.setAttribute('as', 'script')
    } else if (resource.includes('.css')) {
      link.setAttribute('as', 'style')
    }
    document.head.appendChild(link)
  })
}

// 由于copy过来的get请求参数中有变量比如说new Date()，导致sw缓存需要特殊处理比较麻烦
// 这里模拟SW的StaleWhileRevalidate模式实现
export const storageRequest = async ({ key, params }) => {
  let res = undefined
  try {
    // pc虽然是csr，但是先加上兜底
    if (typeof window !== 'undefined') {
      const storageString = window?.localStorage?.getItem(key)
      let storageContent = JSON.parse(storageString)
      if (
        typeof storageString === 'string' &&
        storageString?.length > 0 && 
        storageContent
      ) {
        // 有缓存：直接返回缓存结果，异步更新缓存
        res = storageContent
        schttp(params)
          .then(_ => window?.localStorage?.setItem(key, JSON.stringify(_)))
          .catch((e) => {
            console.error('storageRequest 异步更新 Error', e)
          })
      } else {
        // 无缓存：等待接口返回
        try {
          res = await schttp(params)
          window?.localStorage?.setItem(key, JSON.stringify(res))
        } catch (e) {
          console.error('storageRequest 同步更新 Error', e)
        }
      }
    }
  } catch (e) {
    console.error('storageRequest 未使用缓存 Error', e)
    res = undefined
  } finally {
    return res
  }
}

export const payCardPrefetchFn = (() => {
  let pending = false
  let hasFinished = false
  const CARD_PAYMENT_TYPE = [6, 7]  // 表示是卡支付类型，这里写死很不科学，但是目前没有好办法
  const requestUrl = '/ltspc/getAssets/get'
  return async (paymentType) => {
    // 1.非ROWME环境
    // 2.属于卡支付类型
    // 3.未获取到预缓存资源
    // 4.接口不在在pending中
    // 符合上述4个条件才会发起预取请求
    console.time('payCardPrefetchFn')
    console.log('payCardPrefetchFn paymentType', paymentType)
    console.log('payCardPrefetchFn gbCommonInfo?.IS_RW', gbCommonInfo?.IS_RW)
    try {
      if (
        !gbCommonInfo?.IS_RW &&
        CARD_PAYMENT_TYPE?.includes(paymentType) &&
        hasFinished === false &&
        pending === false
      ) {
        pending = true
        let res = await storageRequest({
          key: `pc_${requestUrl}`,
          params: {
            method: 'GET',
            url: requestUrl,
            params: { _t: +new Date(), site_from: 'pc' }
          }
        })
        console.log('payCardPrefetchFn res', res)
        if(res?.code == 0 && res?.info?.assets?.length > 0){
          prefetchScripts({ resources: res.info.assets })
          hasFinished = true
        }
      }
    } catch (e) {
      console.error('payCardPrefetchFn Error', e)
    } finally {
      pending = false
      console.timeEnd('payCardPrefetchFn')
    }
  }
})()

/**
 * 获取支付限制
 * @param {Array} currencySupport 支付方式币种配置
 * @param {String} currency 当前币种
 * @param {String} paymentCode 支付方式编码，如 oxxo, paypal ..
 * @param {String} countryCode 运输国家简码，如 US, MX ..
 * ---------------
 * @returns {Boolean} isChangeCurrency 是否需要切换币种
 * @returns {Object} currentPayment 当前支付方式的币种配置
 */
export function handleSwitchCurrency({
  currencySupport = [],
  currency,
  paymentCode,
  countryCode,
}) {
  // 强制切换币种
  let isChangeCurrency = false
  let currentPayment = null // 当前可用的支付配置
  let currentPaymentWithoutCountryCode = null // 当不限制国家时的支付方式配置

  if (paymentCode) {
    // 获取当前支付方式配置
    const currentPaymentSupports = currencySupport.filter(
      (item) => item.payMethod == paymentCode
    )
    if (currentPaymentSupports.length == 0) {
      return {
        isChangeCurrency,
        currentPayment,
      }
    }

    for (const i in currentPaymentSupports) {
      if (
        currentPaymentSupports[i].countryCode == countryCode &&
        countryCode != ''
      ) {
        // 有限制国家的配置，直接取，跳出循环
        currentPayment = currentPaymentSupports[i]
        break
      }
      if (currentPaymentSupports[i].countryCode === null) {
        // 非国家限制，兜底
        currentPaymentWithoutCountryCode = currentPaymentSupports[i]
      }
    }

    // 优先取有国家限制的支付方式，没有就取无国家限制的
    currentPayment = currentPayment || currentPaymentWithoutCountryCode

    if (currentPayment) {
      const { currencySupportList = [], defaultCurrency } = currentPayment
      const isSupport = currencySupportList.some((item) => item == currency)

      // 若不支持此币种
      if (!isSupport && defaultCurrency) {
        isChangeCurrency = true
      }
    }
  }

  return {
    isChangeCurrency,
    currentPayment,
  }
}

/**
 * 获取营销裂变提示语
 * @param {Object} extraPromotion 营销裂变信息对象
 */
export function transformExtraPromotionTip({
  language = {},
  extraPromotion = {},
} = {}) {
  const rule = extraPromotion?.marketFission?.rule || {}
  const { reason = 0, differencePrice } = rule
  if (reason == 0) return
  const priceTag = `<em class="text-red">${differencePrice.amountWithSymbol}</em>`

  const reasonLanuageMap = {
    1: language.SHEIN_KEY_PC_18066,
    2: language.SHEIN_KEY_PC_18067,
    3: language.SHEIN_KEY_PC_18068,
    4: language.SHEIN_KEY_PC_18069,
  }
  return _commonTemplate(priceTag, reasonLanuageMap[reason])
}

/**
 * 第三方风控Cybersource初始化
 *
 */
export function initCybersource() {
  window.TPM?.run({
    marketing: 'Cybersource',
    method: '_loadCybersource',
  })
}

export function handleShipMethodsPrice({ defaultShipping, price }) {
  let isNoFree = false
  const { mall_list = [] } = price
  if (!mall_list.length || !defaultShipping.length) return false
  mall_list.forEach(mall => {
    const filterMethod = defaultShipping?.filter(item => item.mall_code == mall.mall_code) || []
    const transport_type = filterMethod?.[0]?.shipping_method?.transport_type || ''
    const filterPrice = mall?.shipping_price_all?.filter(item => item.transport_type == transport_type) || []
    if (filterPrice?.[0]?.price?.amount && +filterPrice?.[0]?.price?.amount > 0) isNoFree = true
  })

  return !isNoFree
}

export function isPromitionGoods(item) {
  const promitionInfoList = item.product?.product_promotion_info?.filter(info => {
    // promotion_product_type 1 或 2 为附属品(1赠品，2换购品)
    return (info.promotion_product_type == 1 || info.promotion_product_type == 2)
  }) || []

  return !!promitionInfoList.length || item.promotion_type_id == '1000'
}

export function filterPromitionGoods(carts = []) {
  // promotion_type_id == '1000' 下单页券赠品
  const cartsList = carts.filter(item => {
    let includePromotion = false
    if(!!item.product?.product_promotion_info?.length) {
      const promitionInfoList = item.product?.product_promotion_info?.filter(info => {
        // promotion_product_type 1 或 2 为附属品
        return info.promotion_product_type == 1 || info.promotion_product_type == 2
      }) || []

      includePromotion = !!promitionInfoList.length
    }
  
    return !includePromotion && item.promotion_type_id != '1000'
  }) || []

  return cartsList
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} products 需要上报的已曝光推荐商品列表
 * @param {Number} location 1: 支付方式上方; 2: 支付方式下方; 3: 虚拟资产下方;
 * @param {Number} index 商品的位置，从上到下，从左到右
 * @return {String}
 */
export const getGoodsListForAnalysis = (products, location, index = 1, el) => {
  let exposedList = products

  // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`价格标识`其它标识`销售或促销属性`mall_mallcode`算法额外标识
  const reportKeys = [
    'goodsId',
    'skc',
    'spuId',
    'index',
    'page',
    'location',
    'recMark',
    'extraMark',
    'priceMark',
    'otherMark',
    'promotion',
    'salesLabels',
    'mallCode',
    'algorithmExtraMark',
  ]
  let results = exposedList.map(item => {
    const { 
      quickship, 
      promotionInfo, 
      brand,
      goods_id, 
      goods_sn, 
      productRelationID,
      ext,
      dynamic_ext,
      salePrice,
      retailPrice,
      mall_code,
      dynamicAndRequestExtTrackInfo,
      pcStandardView,
      estimatedPriceInfo,
      swiperIndex
    } = item
    let otherMark = [], salePromotion = []
    if (quickship) otherMark.push(`show_service_label_QuickShip`)
    if(estimatedPriceInfo?.isABPrice) otherMark.push('estimated_price_3')

    if (promotionInfo.length) {
      let is_brand = brand?.name ? 1 : 0
      let brandCode = brand?.brand_code
      promotionInfo?.some(item => +item?.typeId === 32) && otherMark.push('estimated_price_3')
      // 促销属性
      salePromotion = promotionInfo.map(ele => (
        ['sale', ele.typeId, ele.id, is_brand, brandCode].join('_') 
      ))
    }
    const values = {
      goodsId: goods_id,
      skc: goods_sn,
      spuId: productRelationID,
      index: swiperIndex !== undefined ? Number(swiperIndex) + 1 : index + 1,
      page: 1,
      location,
      recMark: ext?.rec_mark ?? '',
      extraMark: dynamic_ext?.extra ?? '',
      priceMark: `pri_${salePrice.usdAmount}|pri_${retailPrice.usdAmount}`,
      otherMark: otherMark.length ? otherMark.join('|') : '',
      promotion: salePromotion.length ? salePromotion.join('|') : '',
      salesLabels: pcStandardView?.salesLabels?.labelLang ? `show_sales_label_${labelTypeMap.get(pcStandardView?.salesLabels.textType)}_${pcStandardView?.salesLabels.labelId}_${pcStandardView?.salesLabels.labelLang?.replace(/\s+/g, '_')}` : '',
      mallCode: `mall_${mall_code}`,
      algorithmExtraMark: dynamicAndRequestExtTrackInfo ?? '',
    }
    return reportKeys.map(key => values[key]).join('`')
  })

  return results.join(',')
}

export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  
  let copy = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy
}

// abt/没有快捷 => 自动续费包过滤掉
export function autoPrimeProducfilter({ sheinClubRenew, primeProductInfo, paymentMethods }){
  let { primeProductList, limitedPayTypeList } = primeProductInfo
 
  const limitedPayTypeListCode = (limitedPayTypeList || []).map(m => m.code)
  const isLimitedPayUsed = paymentMethods.some(s => limitedPayTypeListCode.includes(s.code) && s.enabled == 1)
  
  if(sheinClubRenew != 1 || !limitedPayTypeList || limitedPayTypeList?.length <= 0 || !isLimitedPayUsed){
    primeProductList = primeProductList.filter(f => f?.product_activity_type != 1)
  }
  return primeProductList
}

// 超省卡根据包code，输出场景下对应的状态
export const scenceBuildStatus = (xtraAllproduct = [], showTipsArrowAbt = false) => {
  const sceneResultAll = deepCopy(xtraAllproduct)
  let resultObjct = {
    'default': {
      isVisible: false,
      iconDoubt: false,
      isTips: false,
      isLeg: false
    }
  }
  if(!sceneResultAll?.length) return resultObjct
  sceneResultAll.forEach(m => {
    const result = {
      isVisible: [10, 12, 13, 15].includes(m._xtraSceneDiscount) ? true : false, // 点击是否出优惠弹窗
      iconDoubt: [13, 15].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, // 优惠弹窗里面是否展示法务弹窗的问号
      isTips: m.product_activity_type == 1 ? [7, 8, 11, 14].includes(m._xtraSceneDiscount) : [8, 11, 14].includes(m._xtraSceneDiscount), // 跳转到券中心（如果是自动续费产品包的时候，需要增加场景7）
      isLeg: [5, 6].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, //点击是否出现法务弹窗
      productInfo: m, // 包体信息
      _xtraSceneDiscount: m._xtraSceneDiscount, // 当前是在哪个场景
    }
    resultObjct[m.product_code] = result
  })
  return resultObjct
}

/**
 * 根据本地化尺码获取展示的尺码
 * @return {String}
 */
export const getProductSize = ({ 
  abt = 'old', 
  sizeAttr = {}, 
  sizeRuleList = [], 
  cacheLocalCountry 
}) => {
  const sizeRuleItem = sizeRuleList.find(item => (
    item.name === sizeAttr.attr_value_name_en ||
      item.size === sizeAttr.attr_value_name_en
  ))
  // 默认尺码
  const defaultSize = sizeAttr.attr_value_name
  // 本地尺码，若没有缓存站点，则为当前站点的本地尺码
  const localSize = sizeRuleItem?.correspond
  const sizeInfo = {
    'old': {
      cartSize: localSize ? `${localSize}(${defaultSize})` : defaultSize,
      localSize: localSize ? `${localSize}(${defaultSize})` : defaultSize
    },
    'new': {
      cartSize: defaultSize,
      localSize: localSize ? `${localSize}(${defaultSize})` : defaultSize
    },
    'local_size_no_default': {
      cartSize: localSize || defaultSize,
      localSize: localSize || defaultSize
    },
    'default_size_no_default': {
      cartSize: defaultSize,
      localSize: localSize || defaultSize
    }
  }

  const sizeByAbt = sizeInfo[abt] || sizeInfo.old

  if (cacheLocalCountry) {
    if (cacheLocalCountry === 'default') return defaultSize
    return sizeByAbt.localSize
  }

  return sizeByAbt.cartSize
}

/* 
* 传入秒级
* startTime 开始时间
* endTime 结束时间
* onCompleted 倒计时结束回调
* cb 倒计时回调 输出 时分秒
*/
export function useCountDown (options = { startTime: '', endTime: '', interval: 1000, onCompleted: null }, cb = () => {}) {
  let { startTime, endTime, interval = 1000, onCompleted = () => {} } = options
  if(!+startTime || !+endTime || endTime - startTime < 1){
    cb({ days: 0, hours: 0, minutes: 0, seconds: 0, seconds_transform: '00', days_transform: '00', hours_transform: '00', minutes_transform: '00', expired: false })
    if (typeof onCompleted === 'function') onCompleted()
    return
  }
  let timer = null
  let countDownTime = (endTime - startTime) * 1000
  
  const parseMs = (milliseconds) => {
    const timeObje = {
      days: Math.floor(milliseconds / 86400000),
      hours: Math.floor(milliseconds / 3600000) % 24,
      minutes: Math.floor(milliseconds / 60000) % 60,
      seconds: Math.floor(milliseconds / 1000) % 60,
    }
    const results = {}
    Object.entries(timeObje).forEach((item)=>{
      if(item[1] < 10){
        results[`${item[0]}_transform`] = `0${item[1]}`
      } else {
        results[`${item[0]}_transform`] = String(item[1])
      }
    })

    return {
      ...timeObje,
      ...results,
      expired: !timeObje.days && !timeObje.hours && !timeObje.minutes && !timeObje.seconds
    }
  }
  
  const clear = () => {
    countDownTime = 0
    clearInterval(timer)
  }
  
  if (countDownTime > 0) {
    cb({
      ...parseMs(countDownTime),
      clear
    })
  
    !timer && (timer = setInterval(() => {
      countDownTime -= interval
      if (countDownTime <= 0) {
        clear()
        if (typeof onCompleted === 'function') onCompleted()
      }
      cb({
        ...parseMs(countDownTime),
        clear
      })
    }, interval))
  }
}

/**
 * 判断该商品是否可退
 * @param {String} SiteUID 站点ID
 * @param {Object} item 商品信息
 * @return {Object} { nonRefundable, nonRefundableInfo } nonRefundable: 是否为不可退商品  nonRefundableInfo: 不可退详情
 */
export function isProductRefundable(SiteUID, item) {
  const isDe = !!~SiteUID.indexOf('de')
  // return_flag: 1-商品分类不可退 2-毛利率过低不可退 3-商品参加活动不可退
  const nonRefundableInfo = {
    // 定制商品不可退
    [NonRefundableType.Customized]: item.product?.customization_flag == 1,
    // 品类不可退
    [NonRefundableType.Category]: [1].includes(+item.return_flag),
    // 促销不可退
    [NonRefundableType.Promotion]: [3].includes(+item.return_flag),
    // 低毛利不可退
    [NonRefundableType.LowGrossMargin]: [2].includes(+item.return_flag),
    // 撤销权不可退（de市场）
    [NonRefundableType.Withdrawal]: isDe && !!item.nonRefundable,
    // 撤销权可退（促销不可退、低毛利不可退、品类不可退）（de市场）
    [NonRefundableType.RefundableWithdrawal]: isDe && [1, 2, 3].includes(+item.return_flag)
  }

  return {
    // 只要有一个不可退类型满足，则该商品不可退
    nonRefundable: Object.values(nonRefundableInfo).some(nonRefundable => !!nonRefundable),
    nonRefundableInfo
  }
}
